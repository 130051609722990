import React, { useEffect } from 'react';
import Layout from '@/layouts';
import Head from 'next/head';
import ErrorPage from '@/components/Common/ErrorPage';
import { AppSettings } from '@/shared/app-common';
import { GetStaticProps, InferGetStaticPropsType } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextPageWithLayout } from '@/pages/_app';

export const getStaticProps: GetStaticProps = async (context) => {
  const { locale } = context;
  const localeRes = await serverSideTranslations(locale, ['common']);
  return {
    props: {
      ...localeRes,
    },
  };
};

const Custom404: NextPageWithLayout<InferGetStaticPropsType<typeof getStaticProps>> = () => {
  return <ErrorPage />;
};

Custom404.getLayout = function getLayout(page: React.ReactElement) {
  return (
    <Layout
      head={
        <Head>
          <title>{AppSettings.title}</title>
          <meta name="title" content={AppSettings.title} />
          <meta name="description" content={AppSettings.desc} />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href={AppSettings.icoPath} />
        </Head>
      }
    >
      {page}
    </Layout>
  );
};

export default Custom404;
