import React, { useEffect } from 'react';
import Link from 'next/link';
import './index.less';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

const ErrorPage = () => {
  const { t } = useTranslation();
  const router = useRouter();

  useEffect(() => {
    setTimeout(() => {
      router.push('/');
    }, 2000);
  }, []);

  return (
    <div className={'errorContainer'}>
      <h2 className={'title'}>{t('404Desc')}</h2>
      <Link className={'backLink'} href="/">
        {t('goBackToHome')}
      </Link>
      <img src="/images/404_error.png" alt="404" />
    </div>
  );
};

export default ErrorPage;
